import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import CommonService from '@/service/common';

export class WorkOrderTimespanEntityModel extends BaseEntityModel {
    @FormControl({
        label: '名称',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getAllWorkOrderOptions,
        required: true,
        readonly: true
    } as FormControlOptionModel)
    serviceId: string = undefined;
    serviceName: string = undefined;

    @FormControl({
        label: '时长类型',
        type: FormControlType.SELECT,
        options: [{ value: 'responseTimeSpan', name: '响应时长' }, { value: 'processTimeSpan', name: '解决时长' }],
        required: true,
        readonly: true
    } as FormControlOptionModel)
    stageId: string = undefined;
    stageName: string = undefined;

    @FormControl({
        label: '开始节点计时方式',
        type: FormControlType.SELECT,
        options: [{ value: 'enter', name: '进入' }, { value: 'leave', name: '离开' }],
        required: true
    } as FormControlOptionModel)
    startTaskType: string = undefined;

    @FormControl({
        label: '开始节点',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getFlowTaskNodes,
        optionsPromiseParam: 'serviceId',
        required: true
    } as FormControlOptionModel)
    startTaskId: string = undefined;

    @FormControl({
        label: '结束节点计时方式',
        type: FormControlType.SELECT,
        options: [{ value: 'enter', name: '进入' }, { value: 'leave', name: '离开' }],
        required: true
    } as FormControlOptionModel)
    endTaskType: string = undefined;

    @FormControl({
        label: '结束节点',
        type: FormControlType.SELECT,
        optionsPromise: CommonService.getFlowTaskNodes,
        optionsPromiseParam: 'serviceId',
        required: true
    } as FormControlOptionModel)
    endTaskId: string = undefined;

    startTaskEntry: string = undefined;
    startTaskExit: string = undefined;
    endTaskEntry: string = undefined;
    endTaskExit: string = undefined;
    startTaskNameEntry: string = undefined;
    startTaskNameExit: string = undefined;
    endTaskNameEntry: string = undefined;
    endTaskNameExit: string = undefined;

    get startNodeName() {
        if (this.startTaskNameEntry) {
            return this.startTaskNameEntry + ' [进入]';
        } else {
            return this.startTaskNameExit + ' [离开]';
        }
    }

    get endNodeName() {
        if (this.endTaskNameEntry) {
            return this.endTaskNameEntry + ' [进入]';
        } else {
            return this.endTaskNameExit + ' [离开]';
        }
    }

    static getTableColumns() {
        return [
            {
                title: '服务名称',
                dataIndex: 'serviceName',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '时长类型',
                dataIndex: 'stageName'
            },
            {
                title: '开始节点',
                dataIndex: 'startNodeName'
            },
            {
                title: '结束节点',
                dataIndex: 'endNodeName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        this.startTaskId = _.get(json, 'startTaskEntry') || _.get(json, 'startTaskExit');
        this.endTaskId = _.get(json, 'endTaskEntry') || _.get(json, 'endTaskExit');

        if (_.get(json, 'startTaskEntry')) {
            this.startTaskType = 'enter';
        }
        if (_.get(json, 'startTaskExit')) {
            this.startTaskType = 'leave';
        }
        if (_.get(json, 'endTaskEntry')) {
            this.endTaskType = 'enter';
        }
        if (_.get(json, 'endTaskExit')) {
            this.endTaskType = 'leave';
        }

        return this;
    }

    toService() {
        if (this.startTaskType === 'enter') {
            this.startTaskEntry = this.startTaskId;
        } else {
            this.startTaskExit = this.startTaskId;
        }
        delete this.startTaskType;
        delete this.startTaskId;

        if (this.endTaskType === 'enter') {
            this.endTaskEntry = this.endTaskId;
        } else {
            this.endTaskExit = this.endTaskId;
        }
        delete this.endTaskType;
        delete this.endTaskId;
        return {
            id: this.id,
            serviceId: this.serviceId,
            stageId: this.stageId,
            stageName: this.stageName,
            startTaskEntry: this.startTaskEntry,
            startTaskExit: this.startTaskExit,
            endTaskEntry: this.endTaskEntry,
            endTaskExit: this.endTaskExit
        };
    }
}

export class WorkOrderTimespanQueryModel extends QueryPageModel {
    keyWords: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
