











































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { WorkOrderTimespanEntityModel, WorkOrderTimespanQueryModel } from '@/entity-model/work-order-timespan-entity';
import WorkOrderTimespanService from '@/service/work-order-timespan';
import CommonService from '@/service/common';

@Component
export default class WorkOrderTimespanListComponent extends TableDialogFormComponent<WorkOrderTimespanEntityModel, WorkOrderTimespanQueryModel> {
    WorkOrderTimespanEntityModel = WorkOrderTimespanEntityModel;

    created() {
        this.initTable({
            service: WorkOrderTimespanService,
            queryModel: new WorkOrderTimespanQueryModel(),
            tableColumns: WorkOrderTimespanEntityModel.getTableColumns()
        });

        this.getList();
    }

    onFormChange(formitem) {
        if (formitem.key === 'serviceId') {
            _.map((this.$refs.formDialog as any).jtlForm.formControlList, item => {
                if (item.key === 'startTaskId' || item.key === 'endTaskId') {
                    CommonService.getFlowTaskNodes((this.$refs.formDialog as any).jtlForm.formModel['serviceId']).then(res => {
                        item.options = res;
                    });
                }
            });
        }
    }
}
