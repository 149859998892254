
import { WorkOrderTimespanEntityModel, WorkOrderTimespanQueryModel } from '@/entity-model/work-order-timespan-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, put } from './request';

const URL_PATH = `${WORKORDER_BASE_REQUEST_PATH}/time-spent-configuration`;

class WorkOrderTimespanService implements ICRUDQ<WorkOrderTimespanEntityModel, WorkOrderTimespanQueryModel> {
    async create(model: WorkOrderTimespanEntityModel):Promise<WorkOrderTimespanEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<WorkOrderTimespanEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new WorkOrderTimespanEntityModel().toModel(res);
    }

    async update(model: WorkOrderTimespanEntityModel):Promise<WorkOrderTimespanEntityModel> {
        const url = `${URL_PATH}`;
        const params = model.toService();
        const res = await put(url, params);
        return res;
    }

    async delete(model: WorkOrderTimespanEntityModel):Promise<WorkOrderTimespanEntityModel> {
        const url = `${URL_PATH}/delete/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: WorkOrderTimespanQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/query`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new WorkOrderTimespanEntityModel().toModel(item));
        return res;
        // 不带分页功能的处理方法
        // return _.map(res, item => item = new WorkOrderTimespanEntityModel().toModel(item));
    }
}

export default new WorkOrderTimespanService();
